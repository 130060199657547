import { Button, Form, Input, Modal, notification, Popover, Spin } from "antd";
import {
    generateRequiredRule as genRqdRule,
    EmailRule,
    useCountdown,
    getCountdownName,
    workoutCountDownTimeStr,
    getRuleObject
} from 'utilities/Utility';
import { refreshUser } from 'store/userSlice';
import { CommonPasswordValidator } from 'utilities/Validators';
import { selectUser } from 'store/userSlice';
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useRef, useState } from "react";
import {
    ChangeLoginDto,
    ChangeLoginOutcome,
    VerificationCodePurpose as VrfctCdPp,
    VerificationCodeType as VrfctCdTp
} from 'data/CustomerPortalTypes'
import { FieldData } from "rc-field-form/lib/interface";
import Bwtt from 'components/Shared/ButtonWithToolTip';
import classNames from "classnames";
import { sendCode, updateBillingContactEmails } from "services/RequestDataService";
import VerificationCodeModal from "../VerificationCodeModal";
import { changeLogin } from 'services/AuthService';
import FilteredInput from "../FilteredInput";
import Captcha from 'components/Shared/Captcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const { Item } = Form;

const validEmail: { name: Extract<keyof ChangeLoginDto, 'newEmailAddress'>, errors: string[] } = {
    name: 'newEmailAddress',
    errors: []
}

const needVerifyEmailError: { name: Extract<keyof ChangeLoginDto, 'newEmailAddress'>, errors: string[] } = {
    name: 'newEmailAddress',
    errors: ['Please verify the new email address before continue.']
}

const wrongPasswordError: { name: Extract<keyof ChangeLoginDto, 'password'>, errors: string[] } = {
    name: 'password',
    errors: ['Please check your password.']
}

const emailOccupiedError: { name: Extract<keyof ChangeLoginDto, 'newEmailAddress'>, errors: string[] } = {
    name: 'newEmailAddress',
    errors: ['This Email address is used by another user.']
}

const keyIcon = {
    className: 'fas fa-key',
    style: {
        marginRight: '10px',
        transform: 'scaleX(-1)'
    }
};

type ChangeLoginFormDto = Omit<ChangeLoginDto, 'isFromUpdateAccountSummary' | 'verifyEmailTicketId'>;

let tmpChangeLoginDto: ChangeLoginFormDto | null = null;

let submitted: boolean = false;

const ChangeEmailForm = ({
    defaultEmail,
    verifyTicketId,
    onChangeSucceeded }: {
        defaultEmail?: string,
        verifyTicketId?: string,
        onChangeSucceeded?: (newEmailAddress: string) => any
    }) => {

    const initialValue: ChangeLoginFormDto = useMemo(() => ({
        newEmailAddress: defaultEmail ?? '',
        password: ''
    }), [defaultEmail]);

    const [form] = Form.useForm<ChangeLoginFormDto>();
    const user = useSelector(selectUser);

    const [occupiedEmails, setOccupiedEmails] = useState<string[]>([]);
    const [emailChanged, setEmailChanged] = useState<boolean>(false);
    const [formErrored, setFormErrored] = useState<boolean>(false);
    const [validNewEmail, setValidNewEmail] = useState<string>('');
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState<boolean>(false);
    const [verifiedNewEmail, setVerifiedNewEmail] = useState<string>(
        (defaultEmail && verifyTicketId)
            ? defaultEmail
            : '');
    const [newEmailVerified, setNewEmailVerified] = useState<boolean>(!!(defaultEmail && verifyTicketId));
    const [emailCountdownLeft, startEmailCountdown] = useCountdown(
        getCountdownName(VrfctCdTp.Email, VrfctCdPp.ChangeLogin), 60)
    const [emailCodeTicketId, setEmailCodeTicketId] = useState<string | null>(verifyTicketId ?? null);
    const [emailErrored, setEmailErrored] = useState<boolean>(false);
    const [emailCodeCorrelationId, setEmailCodeCorrelationId] = useState<string>('');

    const [contactEmailCodeTicketId, setContactEmailCodeTicketId] = useState<string | null>(null);
    const [changingContactEmail, setChangingContactEmail] = useState<boolean>(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [requireCaptcha, setRequireCaptcha] = useState(false);
    const captchaRef = useRef<HCaptcha>(null);

    const [showAskChangeContactEmailModal, setShowAskChangeContactEmailModal] = useState<boolean>(false);

    const dispatch = useDispatch();

    const closeEmailModal = useMemo(() => () => setShowVerifyEmailModal(false), []);

    const onEmailVerified = useMemo(() => (emailTickId: string, newEmail: string) => {
        setEmailCodeTicketId(emailTickId);
        setContactEmailCodeTicketId(emailTickId);
        setVerifiedNewEmail(newEmail);
        setNewEmailVerified(true);
        setShowVerifyEmailModal(false);
    }, []);

    const changeBtnDisableMsg =
        useMemo(() => {
            if (formErrored) {
                return 'Please fix all errors before continue.';
            }

            if (!emailChanged) {
                return 'Login Email unchanged.';
            }

            return '';
        }, [emailChanged, formErrored]);

    const changeBtnDisabled =
        useMemo(() => !emailChanged || formErrored, [emailChanged, formErrored]);

    if (!user?.emailAddress) {
        return <></>;
    }

    const sendVerifyEmailCode = async () => {
        form.setFields([validEmail]);

        startEmailCountdown();

        form.setFields([validEmail]);

        const emailAddress = validNewEmail;

        const codeResult = await sendCode(
            {
                RecipientIdentifier: emailAddress,
                Purpose: VrfctCdPp.ChangeLogin,
                CodeType: VrfctCdTp.Email
            });

        if (codeResult?.success === true && codeResult?.data) {
            setEmailCodeCorrelationId(codeResult.data);
            setShowVerifyEmailModal(true);
        }
        else {
            setShowVerifyEmailModal(false);
        }
    }

    const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {

        let errored = false;

        let emailUpdated = false;
        let emailErrored = false;
        let emailVerified = false;

        let newEmail = '';

        for (let field of allFields) {

            const changePropName = (field.name as unknown as string[])[0] as keyof ChangeLoginDto;

            switch (changePropName) {
                case 'newEmailAddress': {

                    const fieldValueNorm = (field.value as string)?.toLowerCase();
                    const currentValueNorm = user.emailAddress?.toLowerCase();
                    const verifiedValueNorm = verifiedNewEmail?.toLowerCase();

                    newEmail = field.value as string;

                    if (fieldValueNorm !== currentValueNorm) {
                        emailUpdated = true;
                    } else {
                        emailUpdated = false;
                    }

                    if (field.errors?.length
                        && field.errors?.length > 0) {

                        emailErrored = true;
                        errored = true;
                    }
                    else {
                        emailErrored = false;
                    }

                    if (verifiedValueNorm
                        && verifiedValueNorm === fieldValueNorm) {

                        emailVerified = true;
                    } else {

                        emailVerified = false;
                    }

                    break;
                }
                default: {
                    if (field.errors?.length
                        && field.errors?.length > 0) {

                        errored = true;
                    }
                }
            }
        }

        setEmailChanged(emailUpdated);
        setEmailErrored(emailErrored);
        setNewEmailVerified(emailVerified);

        setFormErrored(errored);

        if (newEmail && emailUpdated && !emailErrored) {
            setValidNewEmail(newEmail);
        }
    }

    const changeLoginLocal = async (
        value: ChangeLoginFormDto,
        hCaptchaToken: string | null) => {

        const result = await changeLogin(
            {
                ...value,
                isFromUpdateAccountSummary: !!defaultEmail,
                verifyEmailTicketId: emailCodeTicketId!
            },
            hCaptchaToken);

        if (!result?.success
            || !result?.data) {

            notification.error({
                message: "Error",
                description: "Unable to change login Email."
            });

            return;
        }

        const resData = result.data;

        if (resData.changeLoginOutcome === ChangeLoginOutcome.ChangeEmailAlreadySucceeded
            || resData.changeLoginOutcome === ChangeLoginOutcome.ChangeEmailSucceeded) {

            notification.success({
                message: "Success",
                description: "Successfully changed login Email."
            });

            dispatch(refreshUser());

            onChangeSucceeded?.(verifiedNewEmail);

            // setOccupiedEmails([]);
            setEmailChanged(false);
            setValidNewEmail('');
            setVerifiedNewEmail('');
            setNewEmailVerified(false);
            setEmailCodeTicketId(null);
            setEmailErrored(false);
            setEmailCodeCorrelationId('');

            form.setFieldsValue({
                newEmailAddress: '',
                password: ''
            });

            return;
        }

        if (resData.requireRetryCaptCha) {
            tmpChangeLoginDto = { ...value };

            setShowCaptcha(true);

            return;
        }

        if (resData.requireHCaptcha) {
            setRequireCaptcha(true);
        }

        if (resData.changeLoginOutcome === ChangeLoginOutcome.InvalidPassword) {
            form.setFields([wrongPasswordError]);

            return;
        }

        if (resData.changeLoginOutcome === ChangeLoginOutcome.NewEmailOccupied) {
            form.setFields([emailOccupiedError]);

            if (!occupiedEmails.find(e => e?.toLowerCase() === value.newEmailAddress?.toLowerCase())) {
                setOccupiedEmails([...occupiedEmails, value.newEmailAddress]);
            }

            return;
        }
    }

    const onFinish = async (value: ChangeLoginFormDto) => {
        if (submitted) {
            return;
        }

        submitted = true;

        try {
            if (!newEmailVerified) {
                form.setFields([needVerifyEmailError]);

                return;
            }

            if (requireCaptcha) {

                setShowCaptcha(true);
                setRequireCaptcha(false);
                tmpChangeLoginDto = { ...value };

                return;
            }

            await changeLoginLocal(value, null);
        }
        finally {
            submitted = false;

            if (contactEmailCodeTicketId) {
                setShowAskChangeContactEmailModal(true);
            }
        }
    };

    const onCaptchaVerified = (token: string) => {

        setShowCaptcha(false);
        setRequireCaptcha(false);
        captchaRef?.current?.resetCaptcha();

        changeLoginLocal(tmpChangeLoginDto!, token);
    }

    const updateContactEmails = async () => {
        
        setChangingContactEmail(true);

        if (!user?.currentAccount) {
            return;    
        }

        const result = await updateBillingContactEmails(
            user?.emailAddress,
            contactEmailCodeTicketId,
            user?.accounts.map(a => a.accountId));

        if (!result?.success) {
            setChangingContactEmail(false);
            return;
        }

        notification.success({
            message: "Success",
            description: user.accounts.length > 1 ? `Successfully updated correspondence email for all ${user.accounts.length} accounts.` : 'Successfully updated correspondence email'
        });

        setContactEmailCodeTicketId(null);
        setChangingContactEmail(false);
    }

    return <Spin spinning={showCaptcha || changingContactEmail}>
        <Form
            form={form}
            onFieldsChange={onFieldsChange}
            name="change-login-form"
            layout="vertical"
            initialValues={initialValue}
            style={{ minWidth: '300px', textAlign: 'initial' }}
            onFinish={onFinish}>

            <div className="globird-form-title">
                <i {...keyIcon}></i>Change Login Email
            </div>
            <Item
                label={<label className="globird-form-field-label">Current Login Email Address</label>}>
                <Input
                    readOnly
                    style={{ width: "100%" }}
                    value={user?.emailAddress} />
            </Item>
            <Item
                name="newEmailAddress"
                label={<label className="globird-form-field-label">New Login Email Address</label>}
                rules={[
                    genRqdRule('New Email'),
                    EmailRule,
                    getRuleObject<string>(v => {
                        if (occupiedEmails.find(e => e?.toLowerCase() === v?.toLowerCase())) {
                            return emailOccupiedError.errors[0];
                        }

                        return null;
                    })
                ]}
                >
                <FilteredInput
                    allowClear
                    style={{ width: "100%" }}
                    readOnly={!!defaultEmail}
                    className={classNames({
                        'input-with-button-hidden': !emailChanged
                    })}
                    filterExpression={/\s/g}
                    addonAfter={
                        <Button
                            hidden={!emailChanged}
                            key="change-login-verify-email"
                            disabled={newEmailVerified
                                || emailErrored
                                || emailCountdownLeft > 0}
                            className="inline-verify-button"
                            onClick={sendVerifyEmailCode}>
                            {emailErrored
                                ? 'Verify'
                                : newEmailVerified
                                    ? 'Verified'
                                    : emailCountdownLeft <= 0
                                        ? 'Verify'
                                        : `Verify after (${workoutCountDownTimeStr(emailCountdownLeft)})`}
                        </Button>
                    } />
            </Item>
            <Item
                name="password"
                label={<label className="globird-form-field-label">Verify Your Password</label>}
                rules={[genRqdRule('Password'), CommonPasswordValidator(0)]}>
                <Input.Password
                    allowClear
                    style={{ width: "100%" }}
                />
            </Item>
            <Item>
                <Popover
                    visible={showCaptcha}
                    content={<Captcha
                        ref={captchaRef}
                        onVerify={onCaptchaVerified} />}>
                    <Bwtt
                        disabled={changeBtnDisabled}
                        disableMessage={changeBtnDisableMsg}
                        btnNode="Change"
                        componentClassName={['form-save-button']} />
                </Popover>
            </Item>
            <VerificationCodeModal
                title="Verify Your Email"
                visible={showVerifyEmailModal}
                onCancel={closeEmailModal}
                codePurpose={VrfctCdPp.ChangeLogin}
                codeType={VrfctCdTp.Email}
                corrlelationId={emailCodeCorrelationId}
                recipientIdentifier={validNewEmail}
                onVerificationSucceeded={onEmailVerified}
                resendCountdownLeft={emailCountdownLeft}
                resendCb={sendVerifyEmailCode}
            />
            <Modal
                visible={showAskChangeContactEmailModal}
                okText={user.accounts.length > 1 ? "Yes, please change all" : "Yes, please"}
                cancelText="No, thanks"
                onCancel={() => {setShowAskChangeContactEmailModal(false); setContactEmailCodeTicketId(null);}}
                onOk={() => {updateContactEmails(); setShowAskChangeContactEmailModal(false);}}
                maskClosable={false}>
                {user.accounts.length > 1 ? `Would you also like to use your new MyAccount login email address for account correspondences and bills for all your ${user.accounts.length} accounts?` 
                    : "Would you also like to use your new MyAccount login email address for account correspondences and bills?"}
            </Modal>
        </Form>
    </Spin>
};

export default ChangeEmailForm;
