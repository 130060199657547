import {
    CSSProperties,
    Dispatch,
    Fragment,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    SetStateAction,
    useEffect,
    useState
} from 'react';
import {
    anySmartMeters,
    DataCache,
    generateDateRangeRule as genDayRanRule,
    generateLengthRule as genLenRule,
    generateNonDuplicateRule as genDupRule,
    generateRangeRule as genRanRule,
    generateRequiredRule as genRqdRule,
    getAccountServiceMeters,
    getRuleObject,
    getServiceType,
    getServiceTypeIcon,
    isAllowUploadSelfReadStatus,
    isPendingMoveOutStatus,
    isPendingTransferStatus,
    MeterSerialRule,
    RegisterIdRule,
    ServiceType,
    shouldBeDisabledByDayOffset,
    today
} from 'utilities/Utility';
import {
    AutoComplete,
    Checkbox,
    Col,
    Divider,
    Form,
    FormItemProps,
    Image as AntdImg,
    InputNumber,
    Modal,
    notification,
    Radio,
    RadioChangeEvent,
    Result,
    Row, Select,
    Spin,
    Tooltip,
    TreeSelect
} from 'antd';
import {DefaultOptionType} from 'rc-tree-select/lib/TreeSelect'
import {
    accountHasPendingSelfReadUploadAsync,
    getAccountMetersAsync,
    getAccountServiceStatusDateAsync,
    getLatestBasicMeterReadsForAccount,
    uploadAndParseMeterReadPhotoAsync,
    uploadSelfReadAsync
} from 'services/RequestDataService';
import {
    Account,
    AccountMeterDto,
    AccountServiceHasPendingReadDic,
    AccountServiceInfo,
    AccountServiceStatusDateDic,
    AccountServiceStatusDateDto,
    BasicMeterRead,
    NewRegisterRead,
    NewSelfMeterRead,
    NewSelfReadUpload,
    SelfReadPhotoFileExtensions,
    SelfReadReason,
    UploadParseMeterPhotoResultDto
} from 'data/CustomerPortalTypes';
import GlobirdDivider from 'components/Shared/GlobirdDivider';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import DatePicker, {DatePickerPropsType} from 'components/Shared/DatePicker';
import {FileAddOutlined} from '@ant-design/icons';
import {FormInstance, FormProps, useForm} from 'antd/lib/form/Form';
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import {Rule} from 'rc-field-form/lib/interface';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import styles from './SelfReadForm.module.css';
import CustomUploadWrapper from 'components/Shared/CustomUploadWrapper';
import Reducer from 'image-blob-reduce';
import heic2any from 'heic2any';
import ActionResultPanel from 'components/ActionResultPanel';
import LastReadsInformation from './LastReadsInformation';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import links from 'data/Links';
import moment from 'moment';

let submitted = false;

const { Item: FormItem, List: FormList } = Form;

const pendingReadCache: Record<number, DataCache<{ [key: number]: boolean } | null>> = {};

const SelfReadForm = ({
    currentAccount,
    onFinish: propOnFinish,
    smallScreen
}: {
    currentAccount: Account | null,
    onFinish: () => any,
    smallScreen: boolean
}) => {

    const [form] = useForm<NewSelfReadUpload>();
    const [showNewMeterUpload, setShowNewMeterUpload] = useState<boolean>(true);
    const [accntSvcStatuses, setAccntServiceStatuses] = useState<AccountServiceStatusDateDic | null>(null);
    const [accountMeters, setAccountMeters] = useState<AccountMeterDto[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [accountHasPendingRead, setAccountHasPendingRead] = useState<{ [key: number]: boolean }>({});
    const [disableFormMsg, setDisableFormMsg] = useState<string | ReactElement | null>(null);
    const [cautionNoticeModalMsg, setCautionNoticeModalMsg] = useState<string | null | ReactNode>(null);
    const [metaRefreshTrigger, setMetaRefreshTrigger] = useState<boolean>(true);
    const [siteMultiMeterNoticeHistory, setSiteMultiMeterNoticeHistory] = useState<number[]>([]);
    const [meterMultiRegisterNoticeHistory, setMeterMultiRegisterNoticeHistory] = useState<string[]>([]);
    const [latestBasicMeterReads, setLatestBasicMeterReads] = useState<BasicMeterRead[]>([]);
    const [selfReadReason, setSelfReadReason] = useState<SelfReadReason | null>(null);

    const triggerMetaRefresh = () => setMetaRefreshTrigger(!metaRefreshTrigger);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        getDataRefreshEffect(
            currentAccount,
            setLoading,
            setAccntServiceStatuses,
            setAccountMeters,
            setAccountHasPendingRead,
            setDisableFormMsg,
            setLatestBasicMeterReads),
        [currentAccount, metaRefreshTrigger]);

    if (!currentAccount) {
        return <></>;
    }

    const accountSelfReadMeta: AccountSelfReadMeta = {
        ServiceStatuses: accntSvcStatuses,
        AccountMeters: accountMeters,
        ServicesHavePendingRead: accountHasPendingRead
    };

    return <FormContainer {...{
        smallScreen,
        disableMessage: disableFormMsg,
        loading
    }}>
        <Form {...getFormProps(form, currentAccount, getDftFormValue(), triggerMetaRefresh, propOnFinish)}>
            {(formValue: NewSelfReadUpload) => <>
                <ReadDatePicker {...{ smallScreen, accntSvcStatuses }} />
                <FormList name="reads">
                    {(meterReadFields, { add: _, remove }) => meterReadFields.map(({
                        name: formMeterIndex,
                        // ...meterRestField
                    }) => {
                        const read = formValue.reads[formMeterIndex];
                        const selfReadSectionProps = {
                            formMeterIndex,
                            deleteRead: () => {
                                meterReadFields.length === 1 && setShowNewMeterUpload(true);
                                const newReads = formValue.reads.filter((_, i) => i !== formMeterIndex);

                                setSiteMultiMeterNoticeHistory(
                                    siteMultiMeterNoticeHistory.filter(acsId => newReads.some(r => r.accountServiceId === acsId)));

                                setMeterMultiRegisterNoticeHistory(
                                    meterMultiRegisterNoticeHistory.filter(ms => newReads.some(r => r.meterSerial === ms)));

                                remove(formMeterIndex);
                            },
                            meterRead: read,
                            accountMeters: accountMeters ?? [],
                            setLoading,
                            currentAccount,
                            form,
                            latestBasicMeterReads
                        };
                        return <Fragment key={`self-read-section-${formMeterIndex}`}>
                            <SelfReadSection
                                {...selfReadSectionProps}
                                leftCol={<>
                                    {!read.isMultiRegister && <MeterImg formMeterIndex={formMeterIndex} readPhoto={read.readPhoto!} />}
                                    <MeterSerialInput {...{
                                        formMeterIndex,
                                        currentAccountServiceId: read.accountServiceId,
                                        allAccountMeters: accountMeters,
                                        form,
                                        accountServices: currentAccount.services,
                                        accountSelfReadMeta
                                    }} /></>}
                                rightCol={<>
                                    {!read.isMultiRegister && <MeterIndexInput formMeterIndex={formMeterIndex} currentServiceType={read.serviceType} />}
                                    {read.isMultiRegister ? null : <LastReadsInformation
                                        latestBasicMeterReads={latestBasicMeterReads}
                                        meterRead={read} />}
                                    <SiteTreeSelector {...{
                                        formMeterIndex,
                                        services: currentAccount.services,
                                        currentServiceType: read.serviceType, accountSelfReadMeta,
                                        form,
                                        smallScreen
                                    }} />
                                    {read.serviceType === ServiceType.Power
                                        && <IsMultiRegisterRadioButton
                                            formMeterIndex={formMeterIndex} />}</>} />
                        </Fragment>
                    })
                    }
                </FormList>
                {showNewMeterUpload && <SelfReadUploader
                    hideDeleteIcon={!formValue.reads.length}
                    onChange={getOnFileUpload(
                        form,
                        currentAccount,
                        accountSelfReadMeta,
                        (siteDisableMsg) => setCautionNoticeModalMsg(siteDisableMsg),
                        siteMultiMeterNoticeHistory,
                        setSiteMultiMeterNoticeHistory,
                        meterMultiRegisterNoticeHistory,
                        setMeterMultiRegisterNoticeHistory,
                        () => { setShowNewMeterUpload(false); setLoading(false); },
                        () => setLoading(true))}
                    setShowNewMeterUpload={setShowNewMeterUpload} />}
                <SelfReadReasonOptions selfReadReason={selfReadReason} setSelfReadReason={setSelfReadReason} />
                <div className={styles['form-button-container']}>
                    <Bwtt {...getAddReadBtnProps(
                        formValue.reads.length >= 20
                            ? 'Maximum of 20 meter reads are allowed.'
                            : showNewMeterUpload
                                ? 'Please complete the existing read upload before adding another read.'
                                : null
                        , () => setShowNewMeterUpload(true))} />
                    <div style={{ height: '10px' }}></div>
                    <Bwtt {...getFormBtnProps(
                        'Submit',
                        !formValue.reads.length
                            ? 'Please upload at least one meter read.'
                            : selfReadReason == null
                                ? 'Kindly choose one of the self read reasons.'
                                : null,
                        undefined,
                        () => {
                            form.setFieldsValue({
                                reissueInvoice: selfReadReason === SelfReadReason.ReissueLastBill
                            })
                        }
                    )}
                        htmlType="submit" />
                </div>
            </>}
        </Form >
        <CautionNoticeModal
            cautionNoticeModalMsg={cautionNoticeModalMsg}
            setCautionNoticeModalMsg={setCautionNoticeModalMsg} />
    </FormContainer>;
}

//#region LocalTypes
type FromContainerProp = PropsWithChildren<{
    smallScreen: boolean,
    disableMessage: string | null | ReactElement,
    loading: boolean
}>

type BtwwProp = Parameters<typeof Bwtt>[0];

enum SiteDisableReason {
    SmartMeterSite = 'SmartMeterSite',
    PendingSelfRead = 'PendingSelfRead',
    PendingTransfer = 'PendingTransfer',
    PendingMoveOut = 'PendingMoveOut',
    Closed = 'Closed'
}

const SiteDisableTooltipMsg: Record<SiteDisableReason, string | ReactElement> = {
    [SiteDisableReason.SmartMeterSite]: 'Uploading self read for a site with smart meters is not supported.',
    [SiteDisableReason.PendingSelfRead]: <>This site has a pending self read upload to be reviewed, please wait till the review is completed, or, you can cancel your previous submission <Link to='/selfread'>here</Link>.</>,
    [SiteDisableReason.PendingTransfer]: 'Uploading self read for a service in transfer process is not supported.',
    [SiteDisableReason.PendingMoveOut]: 'Uploading self read is not supported for services those are pending move/transfer out. Please ensure access to your meter for the final read.',
    [SiteDisableReason.Closed]: 'Uploading self read for an inactive service is not supported.',
};

const SiteDisableModalMsg: Record<SiteDisableReason, string | ReactElement> = {
    [SiteDisableReason.SmartMeterSite]: 'It looks like you have a smart meter. Your local distributor will send us your read information. There is no need to submit a self-read.',
    [SiteDisableReason.PendingSelfRead]: <>It looks like you already uploaded a self-read for this meter. Click <Link to='/selfread'>here</Link> to cancel your previous submission and proceed with this upload.</>,
    [SiteDisableReason.PendingTransfer]: 'It doesn\'t look like your service has switched to GloBird yet. We need to wait until your meter has transferred to GloBird before we can accept your self-read.',
    [SiteDisableReason.PendingMoveOut]: 'It looks like your service is in the process of moving away from GloBird. For this final read we need your local distributor to send us your data so it can be used to close your account.',
    [SiteDisableReason.Closed]: 'It looks like you were trying to upload a self read for an inactive service. Please be advised that uploading self read for an inactive service is not supported.',
};

interface AccountSelfReadMeta {
    ServiceStatuses: AccountServiceStatusDateDic | null;
    AccountMeters: AccountMeterDto[] | null;
    ServicesHavePendingRead: {
        [key: number]: boolean;
    };
}
//#endregion

//#region CompressReadPhoto
const maxReadPhotoSize = 15 * 1024 * 1024; // 15M bytes
const targetReadPhotoSize = 500 * 1024; // 500K bytes
const defaultPixCount = 1280;
const maxPhotoUploadSize = defaultPixCount * defaultPixCount * 8.25 / 8.0;

const imageReducer = Reducer();

imageReducer._create_blob = function (env: any) {
    return this.pica.toBlob(env.out_canvas, 'image/jpeg')
        .then(function (blob: any) {
            env.out_blob = blob;
            return env;
        });
};

const compressFileAsync = async (file: File) => {
    if (file.size > maxReadPhotoSize) {
        window.alert('The size of photo must be less than 15MB.');
        return;
    }

    const fileName = file.name;
    const fileNameLwrCse = fileName?.toLowerCase();

    if (fileNameLwrCse?.endsWith('.heic')
        || fileNameLwrCse?.endsWith('.heif')) {

        // not even Safari supports to display HEIC photo, in order to show thumbnail of HEIC photo, need to use converted png blob
        file = new File([(await heic2any({ blob: file })) as Blob], fileName)
    }

    if (file.size > targetReadPhotoSize) {
        file = new File(
            [(await imageReducer.toBlob(
                file,
                { max: defaultPixCount })) as Blob],
            fileName);
    }

    if (file.size > maxPhotoUploadSize) {
        // suspect the photo was taken by a Galaxy A22/A23 phone, which may contains APP4 markers
        // with huge size for unknown reason, or APP1 marker with Abobe XMP(also huge size)
        // try remove APP4 jpeg markers and APP1 marker with Abobe XMP
        try {
            file = new File([await removeInvalidJpegMarkers(file)], fileName);
        } catch {
            window.alert('Invalid meter read photo format.');
            return;
        }
    }

    return file;
}
//#endregion

//#region ValueAndPropertyGenerators
const getDftFormValue: () => NewSelfReadUpload = () => ({
    readDate: today(),
    reissueInvoice: false,
    reads: []
});

const getFormProps = (
    form: FormInstance<NewSelfReadUpload>,
    currentAccount: Account,
    initialValues: NewSelfReadUpload,
    triggerMetaRefresh: () => void,
    propOnFinish?: () => void) => ({
        labelAlign: 'left',
        colon: false,
        className: styles['self-read-form'],
        labelCol: { span: 10 },
        labelWrap: true,
        initialValues,
        form,
        onValuesChange: (changedVal) => {
            const changedReads = changedVal.reads as NewSelfMeterRead[];

            if (!changedReads?.filter(r => r?.accountServiceId).length) {
                return;
            }

            const oldReads = form.getFieldsValue(['reads']).reads as NewSelfMeterRead[];

            const newReads = oldReads.map((r, i) => {
                const oldRead = oldReads[i];

                if (!changedReads[i]?.accountServiceId) {
                    return oldRead;
                }

                return {
                    ...oldRead,
                    serviceType: getServiceType(
                        currentAccount.services.find(
                            acs => r.accountServiceId === acs.accountServiceId)!.siteIdentifier)
                };
            });

            form.setFieldsValue({ reads: newReads });
        },
        onFinish: getFormOnFinish(
            currentAccount.accountId,
            triggerMetaRefresh,
            form,
            propOnFinish)
    }) as FormProps<NewSelfReadUpload>;

const getFormBtnProps = (
    btnTxt: string,
    disableMessage?: string | null,
    btnStyle?: CSSProperties,
    onClick?: () => void) => ({
        useAntdBtn: true,
        type: 'primary',
        size: 'large',
        btnNode: btnTxt,
        disabled: !!disableMessage,
        disableMessage: disableMessage,
        btnStyle: { width: '100%', ...btnStyle },
        onClick
    }) as BtwwProp;

const getAddReadBtnProps = (disableMessage: string | null, onClick: () => void) => ({
    ...getFormBtnProps(
        'Add a read',
        disableMessage,
        disableMessage ? undefined : addReadBtnStyle,
        onClick)
}) as BtwwProp;

const getDftSelfReadValue = (
    updAndParseResult: UploadParseMeterPhotoResultDto,
    currentAccount: Account,
    readPhoto: File) => {

    const accountServiceId = updAndParseResult
        .meter
        ?.accountServiceIds
        ?.find(acs1 => currentAccount.services.some(
            acs2 => acs2.accountServiceId === acs1)) ?? null;

    return {
        meterSerial: updAndParseResult.meter?.meterSerial ?? null,
        readIndex: null,
        readPhotoReference: updAndParseResult.uploadReference,
        isMultiRegister: (updAndParseResult.meter?.registers?.length ?? 0) > 1,
        registerReads: [{
            registerId: null,
            readIndex: null,
            readPhotoReference: updAndParseResult.uploadReference,
            readPhoto,
            allowIgnoreRegisterId: false
        }],
        accountServiceId,
        serviceType: updAndParseResult.meter?.nmi
            ? getServiceType(updAndParseResult.meter.nmi)
            : null,
        readPhoto: readPhoto
    } as NewSelfMeterRead;
};

const getOnFileUpload = (
    form: FormInstance<NewSelfReadUpload>,
    account: Account,
    accountSelfReadMeta: AccountSelfReadMeta,
    onRequireCautionDetected: (disableModalContent: string | null | ReactNode) => void,
    siteMultiMeterNoticeHistory: number[],
    setSiteMultiMeterNoticeHistory: (value: number[]) => void,
    meterMultiRegisterNoticeHistory: string[],
    setMeterMultiRegisterNoticeHistory: (value: string[]) => void,
    onUploaded?: () => void,
    beforeUpload?: () => void
) => (file: File) => {

    beforeUpload?.();

    (async () => {
        try {

            const compressedFile = await compressFileAsync(file);

            if (!compressedFile) {
                return;
            }

            file = compressedFile;

            const updAndParseResult = (await uploadAndParseMeterReadPhotoAsync(account.accountId, file))?.data;

            if (!updAndParseResult?.uploadReference) {
                return; // upload was failed, terminate the process
            }

            const currentFormVal = form.getFieldsValue();

            const newRead = getDftSelfReadValue(
                updAndParseResult,
                account,
                file);

            let disableSiteReason: SiteDisableReason | null;

            if (newRead.accountServiceId
                && (disableSiteReason = resolveDisableSiteReason(
                    account.services.find(acs =>
                        acs.accountServiceId === newRead.accountServiceId)!,
                    accountSelfReadMeta))) {

                onRequireCautionDetected(SiteDisableModalMsg[disableSiteReason]);
                newRead.accountServiceId = null;
                newRead.serviceType = null;
                newRead.isMultiRegister = false;
            }
            else if (newRead.accountServiceId
                && newRead.serviceType
                && newRead.meterSerial) {

                const [cautionMsg, isMultiMeter, isMultiRegister] =
                    resolveRequireSiteCaustionReason(
                        newRead.accountServiceId,
                        newRead.meterSerial,
                        accountSelfReadMeta.AccountMeters,
                        newRead.serviceType,
                        siteMultiMeterNoticeHistory,
                        meterMultiRegisterNoticeHistory);

                isMultiMeter && setSiteMultiMeterNoticeHistory([...siteMultiMeterNoticeHistory, newRead.accountServiceId]);

                isMultiRegister && setMeterMultiRegisterNoticeHistory([
                    ...meterMultiRegisterNoticeHistory,
                    newRead.meterSerial.toLowerCase()
                ])

                cautionMsg && onRequireCautionDetected(cautionMsg);
            }

            const newReads = [...currentFormVal.reads, newRead];

            // console.log(newReads)

            form.setFields([{
                name: 'reads', value: newReads
            }]);

            // (window as any).testForm = form;

            newRead.meterSerial
                && form.validateFields(newReads.map((_, i) => ['reads', i, 'meterSerial'])); // validate duplicate serial
        }
        finally {
            onUploaded?.();
        }
    })();
};

const getDataRefreshEffect = (
    currentAccount: Account | null,
    setLoading: (value: SetStateAction<boolean>) => void,
    setAccntServiceStatuses: (value: SetStateAction<AccountServiceStatusDateDic | null>) => void,
    setAccountMeters: (value: SetStateAction<AccountMeterDto[] | null>) => void,
    setAccountHasPendingRead: (value: SetStateAction<AccountServiceHasPendingReadDic>) => void,
    setDisableFormMsg: (value: SetStateAction<string | ReactElement | null>) => void,
    setLatestBasicMeterReads: (value: SetStateAction<BasicMeterRead[]>) => void
) => () => {
    try {
        setLoading(true);

        if (!currentAccount) {
            return;
        }

        if (!currentAccount.services?.length) {
            setDisableFormMsg(
                'Your account has no active service available for uploading self read.');
            return;
        }
        else {
            setDisableFormMsg(null);
        }


        (async () => {
            let accntSvcs = currentAccount.services;

            const acsStatusesResult = (await getAccountServiceStatusDateAsync(
                currentAccount!.services.map(s => s.accountServiceId)))?.data;

            if (acsStatusesResult) {
                setAccntServiceStatuses(acsStatusesResult);

                accntSvcs = getAllowSelfReadAccntSvcs(
                    accntSvcs,
                    acsStatusesResult);

                if (!accntSvcs.length) {
                    setDisableFormMsg(
                        'Your account has no active service available for uploading self read.');
                    return;
                }
            }

            const accntMtrsResult = (await getAccountMetersAsync(
                currentAccount.accountId,
                true,
                false))?.data;

            if (accntMtrsResult) {
                setAccountMeters(accntMtrsResult);

                accntSvcs = getNonSmrtMtrAccntSvcs(
                    accntSvcs,
                    accntMtrsResult);

                if (!accntSvcs.length) {
                    setDisableFormMsg(
                        'Your account has no service with basic meter which is required by uploading self read.');
                    return;
                }
            }

            const hasPndngRdResult = await getAccountPendingReadAsync(currentAccount.accountId);

            if (hasPndngRdResult) {
                setAccountHasPendingRead(hasPndngRdResult);

                accntSvcs = getNoPndngRdAccntSvcs(
                    accntSvcs,
                    hasPndngRdResult);

                if (!accntSvcs.length) {
                    setDisableFormMsg(
                        <>All services of your account have pending read uploads to be reviewed, please wait till the review is completed, or, you can cancel your previous submissions <Link to='/selfread'>here</Link>.</>);
                    return;
                }
            }

            const latestBasicMeterReadsResult = await getLatestBasicMeterReadsForAccount(currentAccount.accountId);

            if (latestBasicMeterReadsResult?.data) {
                setLatestBasicMeterReads(latestBasicMeterReadsResult.data);
            }
        })();
    }
    finally {
        setLoading(false);
    }
}

const generateFormItemProp = (
    name: number,
    itemName: string,
    label: string | ReactElement | undefined,
    rules?: Rule[] | undefined,
    restProps?: Partial<FormItemProps>): FormItemProps => ({
        name: [name, itemName],
        label: label,
        required: false,
        rules: rules,
        ...restProps
    });

const genMeterSerialItemProp = (name: number, accountMeters: AccountMeterDto[] | null, currentAccountServiceId: number | null): FormItemProps =>
    generateFormItemProp(
        name,
        'meterSerial',
        'Meter Serial',
        [
            genRqdRule('Meter serial'),
            genDupRule(val =>
                val?.reads?.map((r: any) => r.meterSerial) ?? null,
                name,
                'Duplicated meter serial is not allowed.'),
            getCrossSiteSerialRule(currentAccountServiceId, accountMeters)
        ],
        {
            labelCol: { span: 24 },
            style: { width: '200px' }
        });

const genMeterSiteItemProp = (name: number): FormItemProps =>
    generateFormItemProp(
        name,
        'accountServiceId',
        'Meter Site',
        [genRqdRule('Meter site')]);

const getMeterSiteTreeSelectProp = (
    services: AccountServiceInfo[],
    serviceType: ServiceType | null,
    accountSelfReadMeta: AccountSelfReadMeta,
    form: FormInstance<NewSelfReadUpload>,
    formMeterIndex: number,
    smallScreen: boolean) => ({
        // showSearch: true,
        dropdownClassName: styles['meter-site-dropdown'],
        style: { width: '200px' },
        placeholder: 'Please select',
        allowClear: true,
        // open: true,
        treeExpandedKeys: ['gas', 'power'],
        switcherIcon: <></>,
        // disable virtual for testing, will load all node into document when set to false
        virtual: false,
        treeData: getSiteSelectorOptions(
            services,
            accountSelfReadMeta,
            smallScreen),
        serviceType,
        onChange: () => form.validateFields([['reads', formMeterIndex, 'meterSerial']])
    });

const resolveMtrSrialSelectOptions = (
    acsId: number | null,
    accntMtrs: AccountMeterDto[] | null,
    accountServices: AccountServiceInfo[],
    accountSelfReadMeta: AccountSelfReadMeta) => {

    if (!accntMtrs?.length) {
        return [];
    }

    if (acsId) {
        accntMtrs = accntMtrs.filter(m => m.accountServiceIds.some(mAcsId => mAcsId === acsId))
    }

    return accntMtrs
        .filter(am => am.accountServiceIds
            .some(acsId => {
                const matchedAcs = accountServices.find(s => s.accountServiceId === acsId);

                return matchedAcs
                    && !resolveDisableSiteReason(
                        matchedAcs,
                        accountSelfReadMeta);
            }))
        .map(m => {

            const meterServiceType = accountServices.find(s => s.siteIdentifier === m.nmi);

            return ({
                label: `${m.meterSerial} ${meterServiceType ? `- ${meterServiceType.serviceType}` : ''}`,
                value: m.meterSerial
            });
        });
}

const genMeterIndexItemProp = (name: number, serviceType: ServiceType | null): FormItemProps => generateFormItemProp(
    name,
    'readIndex',
    <>
        <span style={{ display: 'inline-block' }}>{serviceType
            ? serviceType === ServiceType.Gas ? 'Reading on Meter in Cubic meters (m³)' : 'Reading on Meter in Kilowatt hours (kW.h)'
            : 'Reading on Meter'}
        </span>
        <div className={styles['meter-read-info-icon']}>
            <Tooltip
                title={<>
                    Handy Tips. See
                    <a href={links.corporateWebsite.howToReadMeterDetails}
                        target="_blank"
                        rel="noopener noreferrer"> real examples </a>
                    of how to read your meter type.
                </>}>
                <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
        </div>
    </>
    ,
    [genRqdRule('Meter index'), genRanRule('Meter index', 0, 999999)],
    { style: { minHeight: '56px', marginBottom: 0 } });

const getSiteSelectorOptions = (
    accntSvcs: AccountServiceInfo[],
    accountSelfReadMeta: AccountSelfReadMeta,
    smallScreen: boolean): DefaultOptionType[] => {

    const gasSvcs = accntSvcs.filter(s => s.serviceType === ServiceType.Gas);
    const pwrSvcs = accntSvcs.filter(s => s.serviceType === ServiceType.Power);

    const result: DefaultOptionType[] = [];

    const resolveTreeChildren = (svcs: AccountServiceInfo[]) => svcs
        .map(svc => {
            const disableReason = resolveDisableSiteReason(
                svc,
                accountSelfReadMeta);

            return {
                value: svc.accountServiceId,
                selectable: !disableReason,
                title: disableReason
                    ? <Tooltip
                        title={SiteDisableTooltipMsg[disableReason]}
                        placement={smallScreen ? "top" : "left"}>
                        <div style={{ position: 'relative', cursor: 'no-drop' }}>
                            {svc.siteAddress}
                            <div className={styles['disable-mask']}></div>
                        </div>
                    </Tooltip>
                    : <div
                        className={styles['meter-site-dropdown-enabled-item']}>
                        {svc.siteAddress}
                    </div>
            };
        })
        .sort((a, b) => a.selectable === b.selectable
            ? a.value > b.value
                ? -1
                : 1
            : a.selectable
                ? -1
                : 1
        );

    gasSvcs.length && result.push(
        {
            value: 'gas',
            title: <>{getServiceTypeIcon(ServiceType.Gas)}&nbsp;<strong>Gas</strong></>,
            selectable: false,
            children: resolveTreeChildren(gasSvcs)
        });

    pwrSvcs.length && result.push(
        {
            value: 'power',
            title: <>{getServiceTypeIcon(ServiceType.Power)}&nbsp;<strong>Electricity</strong></>,
            selectable: false,
            children: resolveTreeChildren(pwrSvcs)
        });

    return result;
}

const getReadImgProps = (
    formReadIndex: number,
    b64ReadPhoto: string,
    formRegisterReadIndex?: number) => ({
        style: {
            width: '150px',
            display: 'block',
            margin: 'auto'
        },
        alt: `meter-read-${formReadIndex}`
            + formRegisterReadIndex !== undefined
            ? `-${formRegisterReadIndex}`
            : '',
        src: b64ReadPhoto
    });

const genRegIdItemProp = (
    formRegisterReadIndex: number,
    formMeterIndex: number,
    ignoreRule: boolean): FormItemProps =>
    generateFormItemProp(
        formRegisterReadIndex,
        'registerId',
        'Register ID',
        ignoreRule
            ? undefined
            : [
                genRqdRule('Register ID'),
                genLenRule('Register ID', 10),
                RegisterIdRule,
                genDupRule(val =>
                    val?.reads?.[formMeterIndex]?.registerReads?.map((r: any) => r.registerId) ?? null,
                    formMeterIndex,
                    'Duplicated Register ID is not allowed.')
            ],
        { style: { minHeight: '56px', marginBottom: 0 } });

const genRegAllowIgnoreIdItemProp = (
    formRegisterReadIndex: number): FormItemProps =>
    generateFormItemProp(
        formRegisterReadIndex,
        'allowIgnoreRegisterId',
        'I don\'t know the Register Id',
        undefined,
        {
            valuePropName: 'checked',
            labelCol: { span: 20 },
            wrapperCol: { span: 4 },
            className: styles['dunno-reg-id-label']
        });

const getFormOnFinish = (
    currentAccountId: number,
    triggerMetaRefresh: () => void,
    form: FormInstance<NewSelfReadUpload>,
    propOnFinish?: () => any
) => async (val: NewSelfReadUpload) => {
    if (submitted || !val) {
        return;
    }

    // console.log(currentAccountId)

    submitted = true;

    try {
        const uploadResult = await uploadSelfReadAsync(
            currentAccountId,
            {
                ...val,
                reads: val.reads!.map(r => ( // all unwanted readIndex/meterSerial/registerReads will be removed by checking isMultiRegister
                    {
                        ...r,
                        readIndex: r.isMultiRegister
                            ? null
                            : r.readIndex,
                        meterSerial: r.meterSerial,
                        readPhotoReference: r.isMultiRegister
                            ? null
                            : r.readPhotoReference,
                        registerReads: r.isMultiRegister
                            ? r.registerReads
                            : []
                    }
                ))
            });

        if (uploadResult?.data) {

            (async () => {
                await refreshAccountPendingReadAsync(currentAccountId);
                triggerMetaRefresh();
            })();

            form.setFieldsValue(getDftFormValue());

            notification.success({
                message: 'Success',
                description: 'Thanks for sending GloBird your meter self-read, we will work with your local distributor to process the read.',
                duration: 10
            });

            propOnFinish?.();
        }
        else {
            notification.error({
                message: 'Error',
                description: 'Unable to upload self read.'
            });
        }
    }
    finally {
        submitted = false;
    }
}

const getLatestFrmpDate = (accntSvcStatuses: AccountServiceStatusDateDic | null): moment.Moment | null => {
    if (!accntSvcStatuses) return null;
    const currentDate = moment();
    
    return Object.values(accntSvcStatuses)
        .map(status => status.frmpDate)
        .filter(date => date && moment(date).isBefore(currentDate))
        .map(date => moment(date))
        .reduce((latest, current) => latest && latest.isAfter(current) ? latest : current, null as moment.Moment | null);
};

const calculateDisableSelfReadDates = (accntSvcStatuses: AccountServiceStatusDateDic | null) => {
    const latestFrmpDate = getLatestFrmpDate(accntSvcStatuses) ?? moment(0);
    const currentDate = moment();
    const diffInDays = currentDate.diff(latestFrmpDate, 'days');
    return diffInDays > disableSelfReadDateBeforeDaysAgo ? disableSelfReadDateBeforeDaysAgo : diffInDays;
};

//#endregion

//#region Styles
const getReadDateLabelStyle: (smallScreen: boolean) => CSSProperties = (smallScreen) => ({
    display: 'inline-block',
    position: 'relative',
    top: smallScreen ? '5px' : '7px',
    fontWeight: 'bold',
    fontSize: smallScreen ? '14px' : '16px'
});

const getReadDatePickerProps: (smallScreen: boolean, accntSvcStatuses: AccountServiceStatusDateDic | null) => DatePickerPropsType = (smallScreen, accntSvcStatuses) => {
    const disableSelfReadDates = calculateDisableSelfReadDates(accntSvcStatuses);

    return{
        style: { width: '100%' },
        id: 'self-read-date',
        disabledDate: (date) => shouldBeDisabledByDayOffset(date, -disableSelfReadDates, 0),
        mode: 'date',
        size: smallScreen ? 'middle' : 'large'
    };
};

const addReadBtnStyle = {
    backgroundColor: 'rgba(251,171,71,1)',
    borderColor: 'rgba(251,171,71,1)'
};
//#endregion

//#region BusinessRules
const disableSelfReadDateBeforeDaysAgo = 3;

const getReadDateRule = (accntSvcStatuses: AccountServiceStatusDateDic | null) => {
    const disableSelfReadDates = calculateDisableSelfReadDates(accntSvcStatuses);

    return [
        genRqdRule('Read date'),
        genDayRanRule(
            `Read date should not be earlier than ${disableSelfReadDates} days prior to today.`,
            'Read date should not be in the future.',
            -disableSelfReadDates,
            0
        )
    ];
};

const getAllowSelfReadAccntSvcs = (
    accntSvcs: AccountServiceInfo[],
    acssStatus: AccountServiceStatusDateDic | null) =>

    accntSvcs.filter(acs => !acssStatus
        || !acssStatus[acs.accountServiceId]
        || isAllowUploadSelfReadStatus(acssStatus[acs.accountServiceId].status)
        || (isPendingMoveOutStatus(acssStatus[acs.accountServiceId].status)
            && isPendingMoveOutAllowSelfRead(acssStatus[acs.accountServiceId])));

const getNonSmrtMtrAccntSvcs = (
    accntSvcs: AccountServiceInfo[],
    accntMtrs: AccountMeterDto[] | null) =>

    accntSvcs.filter(acs => !accntMtrs
        || !anySmartMeters(getAccountServiceMeters(accntMtrs, acs.accountServiceId)));

const getNoPndngRdAccntSvcs = (
    accntSvcs: AccountServiceInfo[],
    pndngRd: AccountServiceHasPendingReadDic | null) =>

    accntSvcs.filter(acs => !pndngRd
        || !pndngRd[acs.accountServiceId]);

const isPendingMoveOutAllowSelfRead = (
    accntStatusDate: AccountServiceStatusDateDto) => {

    if (!isPendingMoveOutStatus(accntStatusDate.status)) {
        throw new Error('The status is not pending move out status.');
    }

    if (!accntStatusDate.accountClosedDate) {
        return false;
    }

    return today().add(8, 'day') <= accntStatusDate.accountClosedDate;
}

const resolveDisableSiteReason = (
    accntSvc: AccountServiceInfo,
    accountSelfReadMeta: AccountSelfReadMeta): SiteDisableReason | null => {

    const { ServiceStatuses: accntStatuss,
        AccountMeters: accntMtrs,
        ServicesHavePendingRead: pndngRd } = accountSelfReadMeta;

    if (accntStatuss
        && accntStatuss[accntSvc.accountServiceId]
        && !isAllowUploadSelfReadStatus(
            accntStatuss[accntSvc.accountServiceId].status)) {

        const accntStatusDate = accntStatuss[accntSvc.accountServiceId];
        const accntSvcStatus = accntStatusDate.status;

        if (isPendingTransferStatus(accntSvcStatus)) {
            return SiteDisableReason.PendingTransfer;
        }

        if (isPendingMoveOutStatus(accntSvcStatus)
            && !isPendingMoveOutAllowSelfRead(accntStatusDate)) {

            return SiteDisableReason.PendingMoveOut;
        } else if (!isPendingMoveOutStatus(accntSvcStatus)) {

            return SiteDisableReason.Closed;
        }
    }

    if (accntMtrs
        && anySmartMeters(getAccountServiceMeters(accntMtrs, accntSvc.accountServiceId))) {

        return SiteDisableReason.SmartMeterSite;
    }

    if (pndngRd?.[accntSvc.accountServiceId]) {

        return SiteDisableReason.PendingSelfRead;
    }

    return null;
}

const resolveRequireSiteCaustionReason = (
    accountServiceId: number,
    meterSerial: string,
    accountMeters: AccountMeterDto[] | null,
    serviceType: ServiceType,
    siteMultiMeterNoticeHistory: number[],
    meterMultiRegisterNoticeHistory: string[]): [string | null, boolean, boolean] => {

    if (!accountMeters) {
        return [null, false, false];
    }

    meterSerial = meterSerial.toLowerCase();

    let isMultiMeter = false;
    let isMultiRegister = false;

    if (!siteMultiMeterNoticeHistory.some(acsId => acsId === accountServiceId)) {

        if (accountMeters.filter(
            am => am.accountServiceIds.some(
                acsId => acsId === accountServiceId)).length > 1) {

            isMultiMeter = true;
        }
    }

    if (!meterMultiRegisterNoticeHistory.some(ms => ms[0] === meterSerial)) {
        const meter = accountMeters
            .find(am => am.accountServiceIds.some(
                acsId => acsId === accountServiceId))!;

        if ((meter.registers?.length ?? 0) > 1) {

            isMultiRegister = true;
        }
    }

    if (isMultiMeter && serviceType === ServiceType.Gas) {
        return ['Thanks for uploading your meter photo. Our records show you may have more than one gas meter at your property. Uploading photos of your other meters will ensure more accurate estimates for you.', true, false];
    }

    if (isMultiMeter && isMultiRegister && serviceType === ServiceType.Power) {
        return ['Thanks for uploading your meter photo. Our records show you may have more than one electricity meter at your property. And this electricity meter may contain more than one register. Uploading photos of your other meters & registers will ensure more accurate estimates for you.', true, true];
    }

    if (isMultiMeter && serviceType === ServiceType.Power) {
        return ['Thanks for uploading your meter photo. Our records show you may have more than one electricity meter at your property. Uploading photos of your other meters will ensure more accurate estimates for you.', true, false];
    }

    if (isMultiRegister && serviceType === ServiceType.Power) {
        return ['Thanks for uploading your meter photo. Our records show this electricity meter may contain more than one register. Uploading photos of other registers will ensure more accurate estimates for you.', false, true];
    }

    // no multi-register gas meter

    return [null, false, false];
}

const getCrossSiteSerialRule = (
    currentAccountServiceId: number | null,
    accountMeters: AccountMeterDto[] | null): Rule => getRuleObject<string>(
        (serial) => {
            if (!accountMeters?.length || !serial || !currentAccountServiceId) {
                return null;
            }

            const actualMeter = accountMeters
                .find(am => am.meterSerial.toLowerCase()
                    === serial.toLowerCase());

            if (!actualMeter || actualMeter.accountServiceIds.some(
                acsId => acsId === currentAccountServiceId)) {
                return null;
            }

            return `This meter belongs to another ${getServiceType(actualMeter.nmi) === ServiceType.Gas ? 'gas' : 'electricity'} site, please select another meter site.`;
        }, false);

const getAccountPendingCacheAsync = async (accountId: number) => (pendingReadCache[accountId]
    ??= await DataCache.Create<{ [key: number]: boolean } | null>(
        async () => (await accountHasPendingSelfReadUploadAsync(accountId))?.data ?? null,
        300000));

const getAccountPendingReadAsync = async (accountId: number) =>
    (await getAccountPendingCacheAsync(accountId)).data;

const refreshAccountPendingReadAsync = async (accountId: number) =>
    await (await getAccountPendingCacheAsync(accountId)).refresh();
//#endregion

//#region Components
const FormContainer = ({
    children,
    smallScreen,
    disableMessage,
    loading
}: FromContainerProp) =>
    <Spin spinning={loading}>
        <div
            className={classnames({
                'field-container': true,
                [styles['small-screen-field-container']]: smallScreen
            })}
            style={{
                paddingBottom: '25px',
                width: smallScreen ? '95%' : '85%',
                position: 'relative'
            }}>
            <DisableUploadMask {...{ disableMessage }} />
            <div style={{ marginBottom: '20px' }}>
                Uploading a photo of your meter will help us improve our estimations and allow us to request an invoice to be reissued.
            </div>
            {children}
        </div>
    </Spin>

const DisableUploadMask = ({ disableMessage }: { disableMessage: string | null | ReactElement }) =>
    disableMessage
        ? <div className={styles['disable-mask']}>
            <Result
                status="warning"
                title={disableMessage} />
        </div>
        : null;

const UploadSection = ({ title, titleAction, children }: PropsWithChildren<{
    title: string,
    titleAction?: ReactElement
}>) =>
    <div className={styles['read-section-container']}>
        <div>
            <p>{title}</p>
            <div>{titleAction}</div>
        </div>
        {children}
    </div>

const SelfReadReasonOptions = ({ selfReadReason, setSelfReadReason }: {
    selfReadReason: SelfReadReason | null,
    setSelfReadReason: Dispatch<SetStateAction<SelfReadReason | null>>
}) => {
    const handleRadioGroupChange = (event: RadioChangeEvent) => {
        setSelfReadReason(event.target.value);
    }

    return (
        <>
            <FormItem name="reissueInvoice" style={{ display: "none" }}>
            </FormItem>
            <FormItem
                label="Self Read Reason"
                className={styles['read-reason-container']}
            >
                <Radio.Group onChange={handleRadioGroupChange}>
                    <Radio value={SelfReadReason.ImproveNextEstimation}>
                        Enhance my ongoing estimation accuracy using this information.
                    </Radio>
                    <Radio value={SelfReadReason.ReissueLastBill}>
                        Reissue my latest invoice using this information.
                    </Radio>
                </Radio.Group>
                {
                    !!selfReadReason
                    &&
                    <p style={{ color: "red", width: "fit-content", marginTop: "10px", marginLeft: "auto", marginRight: "auto" }}>
                        It can take around 5 business days to process your customer read request,
                        <br />
                        and we will notify you once done, or you can check the status on MyAccount.
                    </p>
                }
            </FormItem>
        </>
    )
}

const DeleteMeterButton = ({ onClick }: { onClick?: () => void }) =>
    <div onClick={onClick}>
        <DeleteOutlined
            style={{ fontSize: '23px', color: '#e71d73', cursor: 'pointer' }} />
    </div>;

const ReadDatePicker = ({ smallScreen, accntSvcStatuses }: { smallScreen: boolean, accntSvcStatuses: AccountServiceStatusDateDic | null }) => <div
    className={classnames(
        styles['read-section-container'],
        styles['read-date-picker'])}>
    <div>
        <div>
            <span style={getReadDateLabelStyle(smallScreen)}>
                Recent Meter Read Date
            </span>
        </div>
        <div style={{ width: '60%', minWidth: '170px' }}>
            <div style={{ width: '100%', margin: 'auto', maxWidth: '220px' }}>
                <FormItem
                    name="readDate"
                    // noStyle
                    rules={getReadDateRule(accntSvcStatuses)}>
                    <DatePicker {...getReadDatePickerProps(smallScreen, accntSvcStatuses)} />
                </FormItem>
            </div>
        </div>
    </div>
</div>;

const SelfReadUploader = ({ hideDeleteIcon, setShowNewMeterUpload, onChange }: {
    hideDeleteIcon?: boolean,
    setShowNewMeterUpload: Dispatch<SetStateAction<boolean>>
    onChange?: (f: File) => void,
}) => <UploadSection
    title="New Meter Read"
    titleAction={hideDeleteIcon ? undefined : <DeleteMeterButton onClick={() => setShowNewMeterUpload(false)} />}>
        <CustomUploadWrapper
            accept={SelfReadPhotoFileExtensions}
            onChange={onChange}>
            {(triggerUpload) => <div
                onClick={() => triggerUpload()}
                className={styles['read-uploader']}>
                <FileAddOutlined />
                <span>
                    Start by clicking here to add a photo of meter read.
                </span>
            </div>}
        </CustomUploadWrapper>
    </UploadSection>;

interface SelfReadSectionProps {
    formMeterIndex: number;
    deleteRead: () => void;
    meterRead: NewSelfMeterRead;
    leftCol: ReactElement;
    rightCol: ReactElement;
    accountMeters: AccountMeterDto[];
    setLoading: (value: SetStateAction<boolean>) => void;
    currentAccount: Account;
    form: FormInstance<NewSelfReadUpload>;
    latestBasicMeterReads: BasicMeterRead[] | null;
}

const SelfReadSection = (
    { formMeterIndex,
        deleteRead,
        meterRead,
        leftCol,
        rightCol,
        accountMeters,
        setLoading,
        currentAccount,
        form,
        latestBasicMeterReads }: SelfReadSectionProps) => <div className={styles['read-section-container']}>
        <div>
            <p>Meter Read {formMeterIndex + 1}</p>
            <div onClick={deleteRead} style={{ cursor: 'pointer' }}>
                <DeleteOutlined style={{ fontSize: '23px', color: '#e71d73' }} />
            </div>
        </div>
        <SectionRow {...{ leftCol, rightCol }} />
        {(meterRead.isMultiRegister && meterRead.serviceType === ServiceType.Power)
            && <RegisterReadList {...{
                formMeterIndex,
                setLoading,
                form,
                currentAccount,
                meterRead,
                accountMeters,
                latestBasicMeterReads
            }} />}
    </div >

const InputNumberLoc = (props: {
    small?: boolean,
    value?: string | number,
    precision?: number,
    onChange?: (val?: string | number) => void,
    style?: CSSProperties
}) =>
    <InputNumber
        controls={false}
        value={props.value}
        onChange={props.onChange}
        precision={props.precision}
        style={props.style}
        size={props.small ? 'small' : undefined} />;

const CustomTreeSelect = (props: Parameters<typeof TreeSelect>[0] & { serviceType?: ServiceType | null }) => {
    // console.log(props.serviceType)
    return <>
        <TreeSelect {...props} />
        {props.serviceType && <div className={styles['site-selector-svc-type-icon']}>
            <Tooltip
                title={`This meter read is for a ${props.serviceType?.toLowerCase()} meter.`}>
                {getServiceTypeIcon(props.serviceType)}
            </Tooltip>
        </div>}
    </>
}

const IsMultiRegisterRadioButton = ({ formMeterIndex }: { formMeterIndex: number }) => <FormItem
    {...generateFormItemProp(
        formMeterIndex,
        'isMultiRegister',
        <span style={{
            fontSize: '12px',
            display: 'inline-block',
            marginBottom: '2px'
        }}>
            Does this meter have multiple registers?
        </span>, undefined)}>
    <Radio.Group
        options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
        ]}
        optionType="button"
        buttonStyle="solid" />
</FormItem>;

const SiteTreeSelector = ({ formMeterIndex, services, currentServiceType, accountSelfReadMeta, form, smallScreen }:
    {
        formMeterIndex: number,
        services: AccountServiceInfo[],
        currentServiceType: ServiceType | null,
        accountSelfReadMeta: AccountSelfReadMeta,
        form: FormInstance<NewSelfReadUpload>,
        smallScreen: boolean
    }) => <FormItem {...genMeterSiteItemProp(formMeterIndex)}>
        <CustomTreeSelect
            {...getMeterSiteTreeSelectProp(
                services,
                currentServiceType,
                accountSelfReadMeta,
                form,
                formMeterIndex,
                smallScreen
            )} />
    </FormItem>;

const MeterIndexInput = ({ formMeterIndex, currentServiceType }:
    {
        formMeterIndex: number,
        currentServiceType: ServiceType | null
    }) => <FormItem {...genMeterIndexItemProp(
        formMeterIndex,
        currentServiceType)}>
        <InputNumberLoc
            style={{ width: '200px' }}
            precision={0} />
    </FormItem>;

const MeterSerialInput = ({ formMeterIndex, currentAccountServiceId, allAccountMeters, form, accountServices, accountSelfReadMeta }:
    {
        formMeterIndex: number,
        currentAccountServiceId: number | null,
        allAccountMeters: AccountMeterDto[] | null,
        form: FormInstance<NewSelfReadUpload>,
        accountServices: AccountServiceInfo[],
        accountSelfReadMeta: AccountSelfReadMeta
    }) => <FormItem {...genMeterSerialItemProp(
        formMeterIndex,
        allAccountMeters,
        currentAccountServiceId)}>
        <Select
            options={resolveMtrSrialSelectOptions(
                currentAccountServiceId,
                allAccountMeters,
                accountServices,
                accountSelfReadMeta
            )}
            placeholder={'Please select'}
        />
    </FormItem>;

const MeterImg = ({ formMeterIndex, readPhoto, formRegisterReadIndex }:
    {
        formMeterIndex: number,
        readPhoto: File,
        formRegisterReadIndex?: number
    }) => <FormItem
        style={{ width: '200px', marginBottom: '7px' }}>
        <AntdImg {...getReadImgProps(
            formMeterIndex,
            URL.createObjectURL(readPhoto),
            formRegisterReadIndex)} />
    </FormItem>;

const RegisterIdInput = (
    { formRegisterReadIndex, formMeterIndex, registerRead, accountMeters, meterRead }: {
        formRegisterReadIndex: number,
        formMeterIndex: number,
        registerRead: NewRegisterRead,
        accountMeters: AccountMeterDto[] | null,
        meterRead: NewSelfMeterRead
    }
) => <FormItem {...genRegIdItemProp(
    formRegisterReadIndex,
    formMeterIndex,
    registerRead.allowIgnoreRegisterId)}>
        <AutoComplete
            filterOption
            options={accountMeters && meterRead.accountServiceId
                ? Object.keys(
                    accountMeters
                        .filter(m => m.accountServiceIds.some(acsId => acsId === meterRead.accountServiceId)
                            && (!meterRead.meterSerial
                                || meterRead.meterSerial.toLowerCase() === m.meterSerial.toLowerCase()))
                        .map(m => m.registers
                            ?.map(r => r.registerId as string)
                            .filter(rid => rid) ?? [])
                        .flat()
                        .reduce((p, c) => { p[c] = true; return p; }, {} as any))
                    .map(rid => ({ label: rid, value: rid }))
                : undefined}
            style={{ width: '200px' }}
            disabled={registerRead.allowIgnoreRegisterId} />
    </FormItem>;

const DunnoRegIdCheckbox = (
    { formRegisterReadIndex, formMeterIndex, form }: {
        formRegisterReadIndex: number,
        formMeterIndex: number,
        form: FormInstance<NewSelfReadUpload>
    }
) => <div className={styles['dunno-reg-id-container']}>
        <FormItem {...genRegAllowIgnoreIdItemProp(formRegisterReadIndex)}>
            <Checkbox onChange={() => form.validateFields([[
                'reads',
                formMeterIndex,
                'registerReads',
                formRegisterReadIndex,
                'registerId'
            ]])} />
        </FormItem>
    </div>;

const RemoveRegBtn = (
    { onClick }: { onClick: () => void }
) => <div className={styles['remove-reg-btn']} onClick={onClick}>
        <MinusCircleOutlined />
    </div>;

const RegSeparator = () => <>
    <div style={{ height: '20px' }}></div>
    <Divider className={styles['register-section-separator']} />
</>

const RegSectionTitle = ({ seq }: { seq: number }) =>
    <p className={styles['register-section-title']} >
        Register Read {seq}
    </p>

const SectionRow = ({ leftCol, rightCol, className }: {
    leftCol: ReactElement,
    rightCol: ReactElement,
    className?: string
}) =>
    <Row
        className={className} justify="space-around">
        <Col className={styles['meter-photo-serial-col']}>
            {leftCol}
        </Col>
        <Col style={{ width: '460px' }}>
            <div className={styles['read-section-right-col-container']}>
                {rightCol}
            </div>
        </Col>
    </Row>

const RegisterReadUploader = ({ formMeterIndex, setLoading, form, currentAccount, disableMessage }: {
    formMeterIndex: number,
    setLoading: (value: SetStateAction<boolean>) => void,
    form: FormInstance<NewSelfReadUpload>
    currentAccount: Account,
    disableMessage: string | null
}) => <CustomUploadWrapper
    accept={SelfReadPhotoFileExtensions}
    onChange={file => {
        setLoading(true);

        (async () => {
            try {

                const compressedFile = await compressFileAsync(file);

                if (!compressedFile) {
                    return;
                }

                file = compressedFile;

                const updAndParseResult = (await uploadAndParseMeterReadPhotoAsync(
                    currentAccount.accountId, file, true))?.data;

                if (!updAndParseResult?.uploadReference) {
                    return; // upload was failed, terminate the process
                }

                form.setFields([{
                    name: 'reads', value: form.getFieldsValue().reads.map((r, i) => {
                        if (i !== formMeterIndex) {
                            return r;
                        }

                        return {
                            ...r,
                            registerReads: [...r.registerReads, {
                                registerId: null,
                                readIndex: null,
                                readPhotoReference: updAndParseResult.uploadReference,
                                readPhoto: file,
                                allowIgnoreRegisterId: false
                            }]
                        };
                    })
                }]);
            }
            finally {
                setLoading(false);
            }
        })();

    }}>
        {(triggerUpload) => <div
            onClick={disableMessage ? undefined : () => triggerUpload()}
            className={styles['register-uploader']}>
            <FileAddOutlined />
            <span>
                Click here to add a photo of another register read.
            </span>
            {disableMessage && <Tooltip title={disableMessage}>
                <div className={styles['disable-mask']} style={{ cursor: 'no-drop' }}>
                </div>
            </Tooltip>}
        </div>}
    </CustomUploadWrapper>;

const RegisterReadList = ({ formMeterIndex, setLoading, form, currentAccount, meterRead, accountMeters, latestBasicMeterReads }: {
    formMeterIndex: number,
    setLoading: (value: SetStateAction<boolean>) => void,
    form: FormInstance<NewSelfReadUpload>
    currentAccount: Account,
    meterRead: NewSelfMeterRead,
    accountMeters: AccountMeterDto[],
    latestBasicMeterReads: BasicMeterRead[] | null
}) => <><GlobirdDivider />
        <FormList name={[formMeterIndex, 'registerReads']}>
            {(registerReadFields, { add: _, remove }) =>
                registerReadFields.map(({
                    name: formRegisterReadIndex,
                    // ...meterRestField
                }) => {
                    const registerRead = meterRead.registerReads[formRegisterReadIndex];
                    return <Fragment key={`self-read-section-${formMeterIndex}-${formRegisterReadIndex}`}>
                        <RegSectionTitle seq={formRegisterReadIndex + 1} />
                        <SectionRow
                            leftCol={<>
                                <MeterImg {...{
                                    formMeterIndex,
                                    readPhoto: registerRead.readPhoto!,
                                    formRegisterReadIndex
                                }} />
                                <div style={{ height: '20px' }}></div></>}
                            rightCol={<>
                                <RegisterIdInput {...{ formRegisterReadIndex, formMeterIndex, registerRead, accountMeters, meterRead }} />
                                <DunnoRegIdCheckbox {...{ formRegisterReadIndex, formMeterIndex, form }} />
                                {registerReadFields.length > 1 && <RemoveRegBtn onClick={() => remove(formRegisterReadIndex)} />}
                                <MeterIndexInput
                                    formMeterIndex={formRegisterReadIndex}
                                    currentServiceType={ServiceType.Power} />{/* {Borrow Meter Index Component} */}
                                <LastReadsInformation
                                    latestBasicMeterReads={latestBasicMeterReads}
                                    meterRead={meterRead}
                                    registerRead={registerRead} />
                            </>}
                            className={styles['register-read-row']} />
                        <RegSeparator />
                    </Fragment>
                })
            }
        </FormList>
        <RegisterReadUploader {...{
            formMeterIndex,
            setLoading,
            form,
            currentAccount,
            disableMessage: meterRead.registerReads.length >= 15
                ? 'Maximum of 15 register reads are allowed.'
                : null
        }} />
    </>

const CautionNoticeModal = ({ cautionNoticeModalMsg: disableSiteNoticeModalMsg, setCautionNoticeModalMsg: setDisableSiteNoticeModalMsg }:
    {
        cautionNoticeModalMsg: string | null | ReactNode,
        setCautionNoticeModalMsg: (value: SetStateAction<ReactNode>) => void
    }) => <Modal
        visible={!!disableSiteNoticeModalMsg}
        maskClosable={false}
        onCancel={() => setDisableSiteNoticeModalMsg(null)}
        onOk={() => setDisableSiteNoticeModalMsg(null)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { minWidth: '100px' } }}
        title="Caution">
        <ActionResultPanel status="warning">
            {disableSiteNoticeModalMsg}
        </ActionResultPanel>
    </Modal>;
//#endregion

const removeInvalidJpegMarkers = (jpegBlob: Blob) => new Promise<Blob>((resolve, reject) => {
    // Read the blob as an ArrayBuffer
    const reader = new FileReader();

    reader.onload = function (event) {

        const arrayBuffer = event.target!.result as ArrayBuffer;

        const dataView = new DataView(arrayBuffer);

        let offset = 2; // Skip the initial start of image marker (SOI)
        let length = arrayBuffer.byteLength;

        // Temporary array to hold new JPEG data without APP4
        let newJPEGData = [new Uint8Array(arrayBuffer.slice(0, offset))];

        while (offset < length) {
            // console.log(`${offset}/${length}`)

            // Check if the marker is at current offset
            if (dataView.getUint16(offset) === 0xFFE4
                || (dataView.getUint16(offset) === 0xFFE1
                    && dataView.getUint32(offset + 4) !== 0x45786966)) {
                // Found APP4 marker, or APP1 marker that is not Exif
                const markerLength = dataView.getUint16(offset + 2);
                offset += 2 + markerLength;
            }
            else {
                // Not an APP4 marker, find the next marker
                // 2 bytes for the marker itself
                let nextOffset = offset + 2;

                // Some markers like SOI and EOI have no data segment following them
                if (dataView.getUint16(offset) !== 0xFFD9) {

                    let markerLength = dataView.getUint16(nextOffset);

                    nextOffset += markerLength;
                }

                // Add everything up to the next marker to the new JPEG data
                newJPEGData.push(new Uint8Array(arrayBuffer.slice(offset, nextOffset)));

                offset = nextOffset;
            }
        }

        // // Combine all the Uint8Arrays to one
        let combinedData = new Uint8Array(newJPEGData.reduce<Number[]>(
            (acc, val) => acc.concat(Array.from(val)), []) as any);

        // // Convert back to a Blob
        let newBlob = new Blob([combinedData], { type: 'image/jpeg' });

        resolve(newBlob);
    };

    reader.onerror = error => reject(error);

    reader.readAsArrayBuffer(jpegBlob);
});


export default SelfReadForm;

export { refreshAccountPendingReadAsync };